import templateUrl from './dialog-project-claims-history.html'
import './dialog-project-claims-history.scss'

const DialogProjectClaimsHistoryComponent = {
  bindings: {},
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: true,
  clickOutsideToClose: true,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogProjectClaimsHistory {
    constructor ($mdDialog, $http, Configuration) {
      'ngInject'
      this._identify = 'DialogProjectClaimsHistoryComponent'
      this.$mdDialog = $mdDialog
      this.$http = $http
      this.Configuration = Configuration
      this.claims = []
    }


    $onInit () {
      this.$http
        .get(`${this.Configuration.apiUrl}/admin/projects/engagement_claim_events?project_id=${this.project.id}`)
        .then(response => {
          this.claims = response.data
        })
    }
  }
}

export default DialogProjectClaimsHistoryComponent
