import DialogQualityReviewConfirmationComponent from './dialog-quality-review-confirmation/dialog-quality-review-confirmation.component'
import DialogQualityReviewHistoryComponent from './dialog-quality-review-history/dialog-quality-review-history.component'
import templateUrl from './project-widget-quality-review.html'
import './project-widget-quality-review.scss'

const ProjectWidgetQualityReviewComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetQualityReview {
    constructor ($mdDialog, Configuration, $http) {
      'ngInject'
      this._identify = 'ProjectWidgetPricesComponent'
      this.$mdDialog = $mdDialog
      this.Configuration = Configuration
      this.$http = $http
    }

    openHistoryDialog () {
      this.$mdDialog.show({
        ...DialogQualityReviewHistoryComponent,
        locals: {
          project: this.project
        }
      })
    }

    openConfirmationDialog (action) {
      const reviewPod = this.project.expertPodsProjects.find(pod => pod.expertPod.role === 'review')
      this.$mdDialog.show({
        ...DialogQualityReviewConfirmationComponent,
        locals: {
          project: this.project,
          action,
          podId: reviewPod.expertPod.id
        }
      }).then((response) => {
        this.performAction(response)
      }, () => {
      })
    }

    get hasAddTasks () {
      return this.project && this.project.subtasks
    }

    get canEscalate () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'escalate')
    }

    get canApprove () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'approve')
    }

    get canReject () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'reject')
    }

    get canUnclaim () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'unclaim')
    }

    get canAssign () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'claim')
    }

    performAction (action) {
      return this.$http({
        url: `${this.Configuration.apiUrl}${action.url}`,
        data: action.payload,
        method: action.verb
      })
        .then(response => {
          this.project = response.data
        })
    }
  }
}

export default ProjectWidgetQualityReviewComponent
