import templateUrl from './project-widget-project-claims.html'
import './project-widget-project-claims.scss'
import DialogProjectClaimsHistoryComponent from './dialog-project-claims-history/dialog-project-claims-history.component'
import DialogProjectClaimsConfirmationComponent from './dialog-project-claims-confirmation/dialog-project-claims-confirmation.component'

const ProjectWidgetProjectClaimsComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetProjectClaims {
    constructor ($mdDialog, Configuration, $http, $mdToast) {
      'ngInject'
      this._identify = 'ProjectWidgetPricesComponent'
      this.$mdDialog = $mdDialog
      this.Configuration = Configuration
      this.$http = $http
      this.$mdToast = $mdToast
      this.editingMaxClaims = false
      this.newMaxClaims = 0
    }

    $onInit () {
      if (this.project && this.project.engagementClaims) {
        this.newMaxClaims = this.project.engagementClaims.maxClaimCount
      }
    }

    toggleEditMaxClaims () {
      this.editingMaxClaims = !this.editingMaxClaims
      if (this.editingMaxClaims) {
        this.newMaxClaims = this.project.engagementClaims.maxClaimCount
      }
    }

    updateMaxClaims () {
      if (!this.newMaxClaims || this.newMaxClaims < 1) {
        this.showToast('Maximum claims must be at least 1')
        return
      }

      if (this.newMaxClaims === this.project.engagementClaims.maxClaimCount) {
        this.editingMaxClaims = false
        return
      }

      this.$http({
        url: `${this.Configuration.apiUrl}/admin/projects/engagement_claim_settings`,
        method: 'PATCH',
        data: {
          project_id: this.project.id,
          new_max: this.newMaxClaims
        }
      })
        .then(response => {
          this.project.engagementClaims.maxClaimCount = this.newMaxClaims
          this.project.engagementClaims.availableClaimCount =
          this.project.engagementClaims.maxClaimCount -
          this.project.engagementClaims.currentClaimCount

          this.editingMaxClaims = false
          this.showToast('Maximum claims updated successfully')
        })
        .catch(error => {
          console.error('Error updating maximum claims:', error)
          this.showToast('Failed to update maximum claims')
        })
    }

    showToast (message) {
      this.$mdToast.show(
        this.$mdToast.simple()
          .textContent(message)
          .position('top right')
          .hideDelay(3000)
      )
    }

    openHistoryDialog () {
      this.$mdDialog.show({
        ...DialogProjectClaimsHistoryComponent,
        locals: {
          project: this.project
        }
      })
    }

    openConfirmationDialog (claimant, action) {
      this.$mdDialog.show({
        ...DialogProjectClaimsConfirmationComponent,
        locals: {
          project: this.project,
          claimant,
          action,
        }
      }).then((response) => {
        window.location.reload()
      }, () => {
      })
    }

    get hasAddTasks () {
      return this.project && this.project.subtasks
    }

    get canEscalate () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'escalate')
    }

    get canApprove () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'approve')
    }

    get canReject () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'reject')
    }

    get canUnclaim () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'unclaim')
    }

    get canAssign () {
      return this.project.qualityReview && this.project.qualityReview.actions.find(action => action.name === 'claim')
    }

    hasRemoveAction (claimant) {
      if (!claimant.actions) {
        return false
      }

      for (var i = 0; i < claimant.actions.length; i++) {
        if (claimant.actions[i].name === 'remove') {
          return true
        }
      }

      return false
    };

    performAction (action) {
      return this.$http({
        url: `${this.Configuration.apiUrl}${action.url}`,
        data: action.payload,
        method: action.verb
      })
        .then(response => {
          this.project = response.data
        })
    }
  }
}

export default ProjectWidgetProjectClaimsComponent
