import templateUrl from './dialog-project-claims-confirmation.html'

const DialogProjectClaimsConfirmationComponent = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  controller: class DialogProjectClaimsConfirmation {
    constructor ($mdDialog, Configuration, $http) {
      'ngInject'
      this._identify = 'DialogQualityReviewConfirmationComponent'
      this.$mdDialog = $mdDialog
      this.eligibleExpertsList = []
      this.Configuration = Configuration
      this.$http = $http

      this.formData = {
        template: null,
        content: null,
        private: false,
        searchText: ''
      }

      this.modelOptions = {
        allowInvalid: true,
        updateOn: 'default blur',
        debounce: {
          'default': 500,
          'blur': 0,
          '*': 500
        }
      }
    }


    $onInit () {
      this.isLoading = false
    }

    selectExpert (template) {
      this.action.payload = { ...this.action.payload, expertId: this.formData.expert.id }
      this.clearSearchText()
    }

    clearSearchText () {
      this.formData.searchText = ''
    }

    searchTemplateOnKeydown ($event) {
      console.log('searchTemplateOnKeydown', $event)
      const ignoreKeys = ['Escape', 'ArrowDown', 'ArrowUp']
      if (!ignoreKeys.includes($event.key)) {
        $event.stopPropagation()
      }
    }

    removeClaim () {
      this.isProcessing = true
      return this.$http
        .delete(`${this.Configuration.apiUrl}/admin/projects/engagement_claims/${this.claimant.id}`)
        .then(response => {
          this.$mdDialog.hide({ success: true, data: response.data })
        })
        .catch(error => {
          console.error('Error removing claim:', error)
          this.$mdDialog.hide({ success: false, error })
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    hasRemoveAction (claimant) {
      if (!claimant || !claimant.actions) {
        return false
      }

      for (let i = 0; i < claimant.actions.length; i++) {
        if (claimant.actions[i].name === 'remove' &&
            claimant.actions[i].verb === 'DELETE') {
          return true
        }
      }

      return false
    }
  }
}

export default DialogProjectClaimsConfirmationComponent
