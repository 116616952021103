import ProjectPageComponent from './project-page.component'

import ProjectHeaderModule from './project-header'
import ProjectWidgetTypeModule from './project-widget-type'
import ProjectWidgetBudgetModule from './project-widget-budget'
import ProjectWidgetDatesModule from './project-widget-dates'
import ProjectWidgetPricesModule from './project-widget-prices'
import ProjectWidgetClientModule from './project-widget-client'
import ProjectWidgetExpertModule from './project-widget-expert'
import ProjectWidgetComplexityUrgencyModule from './project-widget-complexity-urgency'
import ProjectWidgetDescriptionModule from './project-widget-description'
import ProjectWidgetScopeDocModule from './project-widget-scope-doc'
import ProjectAttachmentsModule from './project-attachments'
import ProjectWidgetQualityReviewModule from './project-widget-quality-review'


import ProjectActionsModule from './project-actions'



import ProjectWidgetExpertPodsModule from './project-widget-expert-pods'
import ProjectWidgetPreferredExpertsModule from './project-widget-preferred-experts'
import BadgeProjectReferooAgencyModule from './badge-project-referoo-agency'
import ProjectSubmissionMetaModule from './project-submission-meta'
import ProjectWidgetProjectClaimsModule from './project-widget-project-claims'

const ProjectPageModule = angular.module('cdbl.projects.project-page', [
  ProjectHeaderModule,
  ProjectWidgetTypeModule,
  ProjectWidgetBudgetModule,
  ProjectWidgetDatesModule,
  ProjectWidgetPricesModule,
  ProjectWidgetClientModule,
  ProjectWidgetExpertModule,
  ProjectAttachmentsModule,
  ProjectWidgetComplexityUrgencyModule,
  ProjectWidgetDescriptionModule,
  ProjectWidgetScopeDocModule,
  ProjectWidgetQualityReviewModule,
  ProjectWidgetProjectClaimsModule,
  ProjectActionsModule,

  ProjectWidgetExpertPodsModule,
  ProjectWidgetPreferredExpertsModule,
  BadgeProjectReferooAgencyModule,
  ProjectSubmissionMetaModule,
])
  .component('cdblProjectPage', ProjectPageComponent)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
      // parent: '',
        name: 'projects.projectsList.projectsView',
        url: '/:id',
        params: {
          id: { squash: true, value: null, dynamic: true }
        },
        // component: 'cdblProjectPage',
        views: {
          '@projects': 'cdblProjectPage'
        },
        resolve: {
          permissionResolver: ($state, Configuration, AuthService) => {
            'ngInject'
            console.log('[permissionResolver] user', AuthService.user)
            const allow = !Configuration.isBuild && Configuration.CDBL_ENV === 'develop' && AuthService.isAuthenticated && AuthService.user.id === 13
            if (!allow) {
              console.log('[permissionResolver] > reject')
              $state.go('tasks.state')
            }
          }
        }
      })

    $uiRouterProvider.stateRegistry
      .register({
      // parent: '',
        name: 'projects.projectsListAlt.projectsPreview',
        url: '/:id',
        params: {
          id: { squash: true, value: null, dynamic: true }
        },
        // component: 'cdblProjectPage',
        views: {
          'pageItemView': 'cdblProjectPage'
        },
        resolve: {
          permissionResolver: ($state, Configuration, AuthService) => {
            'ngInject'
            console.log('[permissionResolver] user', AuthService.user)
            const allow = !Configuration.isBuild && Configuration.CDBL_ENV === 'develop' && AuthService.isAuthenticated && AuthService.user.id === 13
            if (!allow) {
              console.log('[permissionResolver] > reject')
              $state.go('tasks.state')
            }
          }
        }
      })
  })
  .name

export default ProjectPageModule
